import * as React from 'react';
import { Link } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import BatchesIcon from '@mui/icons-material/GroupsOutlined';
import TemplatesIcon from '@mui/icons-material/ArticleOutlined';
import StudentsIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AddIcon from '@mui/icons-material/Add';
import TutorialIcon from '@mui/icons-material/ModelTraining';

import CustomLink from './CustomLink';
import { areRolesAllowed } from 'utils/roles';
import { Session } from 'utils';
import CenterFlexBox from './CenterFlexBox';
import { useTour } from 'contexts';
import LogoutButton from './LogoutButton';
import { QuestionAnswerRounded } from '@mui/icons-material';

const SideBarLinks = [
    {
        label: "Home",
        path: '/home',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: HomeIcon
    },
    {
        label: "Batches",
        path: '/batches',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: BatchesIcon,
    },
    {
        label: "Assessments",
        path: '/assessments',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: TemplatesIcon,
    },
    {
        label: "Mock Interview",
        path: '/personalizedInterview',
        allowedRoles: ["CLIENT"],
        icon: QuestionAnswerRounded,
    },
    {
        label: "Students",
        path: '/students',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: StudentsIcon,
    },
    {
        label: "Settings",
        path: '/settings',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: SettingsIcon,
    },
];;

const useStyles = makeStyles(theme => ({
    sideBarRoot: {
        bottom: "0px",
        left: "0px",
        position: "fixed",
        top: "0",
        zIndex: 200,
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 0px 4px rgb(0 0 0 / 15%)',
        borderBottom: '1px solid rgb(0 0 0 / 15%)',
        display: "flex",
        flexDirection: "column",
        minWidth: 242,
        width: 242,
    },
    sideBar: {
        boxSizing: 'border-box',
        border: 'none',
        minWidth: 242,
        padding: 16,
        flexGrow: 2,
    },
    menuItem: {
        width: "100%",
        textDecoration: 'unset',
        color: 'black',
        display: "flex",
        alignItems: "center",
        padding: "8px",
        transition: "200ms ease-in-out",
        fontWeight: 500,
        fontFamily: 'Inter',
        borderRadius: 4,
        cursor: "pointer",
        "&:hover": {
            background: "#C5D8E836",
            marginLeft: 2,
        }
    },
    activeMenuItem: {
        border: "1px solid #02569D",
        fontFamily: 'Inter',
        borderRadius: 4,
        background: "#C5D8E8",
        width: "100%",
        textDecoration: 'unset',
        color: '#02569D',
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        padding: "8px",
        marginLeft: 2,
    },
    menuItemText: {
        transformOrigin: "0 50% 0",
        overflow: "hidden",
        height: "1.6em",
        marginLeft: '1em',
        color: "inherit",
        paddingTop: 2,
    },
}));

function CreateTemplateMenu() {
    const classes = useStyles();

    return (
        <CustomLink
            className={classes.activeMenuItem}
            activeClassName={classes.activeMenuItem}
            to={"/assessments/create"}
            style={{ marginLeft: 0, height: 56 }}
        >
            <AddIcon
                fontSize="small"
                style={{
                    color: "#02569D", background: "white", borderRadius: "50%"
                }}
            />
            <Typography
                variant='body2'
                className={classes.menuItemText}
                fontWeight="inherit"
            >
                Add New Assessment
            </Typography>
        </CustomLink>
    );
}

function MenuItem({ menu }) {
    const classes = useStyles();

    const MenuIcon = menu.icon;

    return areRolesAllowed(...menu.allowedRoles) ? (
        <CustomLink
            className={classes.menuItem}
            activeClassName={classes.activeMenuItem}
            to={menu.path}
        >
            <MenuIcon fontSize="small" />
            <Typography
                variant='body2'
                className={classes.menuItemText}
                fontWeight="inherit"
            >
                {menu.label}
            </Typography>
        </CustomLink>
    ) : null;
}

function TutorialButton() {
    const classes = useStyles();
    const { show } = useTour();

    return (
        <Typography
            className={classes.menuItem} onClick={show} id="tutorial-button"
        >
            <TutorialIcon fontSize="small" />
            <Typography
                variant='body2'
                className={classes.menuItemText}
                fontWeight="inherit"
            >
                Tutorial
            </Typography>
        </Typography>
    );
}

export default function Sidebar() {
    const classes = useStyles();

    const {
        logoUrl,
        inprepLogoTransform: transform
    } = Session.getInprepLogoAndTransform();

    return (
        <Box className={classes.sideBarRoot}>
            <CenterFlexBox py={1} px={4} >
                <Link to="/home">
                    <img
                        src={(logoUrl) ? logoUrl : 'https://assets.languify.in/sep-powered-by-languify.svg'}
                        alt={Session?.user?.name || 'languify'}
                        style={{
                            height: '60px',
                            width: 'auto',
                            transform:
                                `translate(${transform?.translate?.x || 0}px, 
                                ${transform?.translate?.y || 0}px) 
                                rotate(${transform?.rotation || 0}deg) 
                                scale(${transform?.scale || 1})`
                        }}
                    />
                </Link>
            </CenterFlexBox>
            <Box className={classes.sideBar}>
                {SideBarLinks.map((sideBarLink, index) => (
                    <MenuItem key={index} menu={sideBarLink} />
                ))}
            </Box>
            <Box p={2} flexGrow={0}>
                <TutorialButton /><br />
                <CreateTemplateMenu /> <br />
                <LogoutButton />
            </Box>
        </Box>
    );
}
